<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="departmentSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm khoa/bộ môn' : 'Cập nhật khoa/bộ môn'"
      @show="onShow"
      @hide="onHide"
      size="lg"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-row>
          <b-col cols="8">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên khoa/bộ môn <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Tên khoa/bộ môn"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model.trim="targetItem.name"
                    name="name"
                    placeholder="Nhập tên khoa/bộ môn"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã khoa/bộ môn <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Mã khoa/bộ môn"
                  rules="required"
              >
                <b-form-input
                    id="code"
                    v-model.trim="targetItem.code"
                    name="code"
                    placeholder="Nhập mã khoa/bộ môn"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Trưởng khoa/bộ môn"
                label-for="leader"
            >
              <v-select
                  v-model="targetItem.leaderId"
                  :options="employeeOptions"
                  :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group label-for="typeId">
              <template v-slot:label>
                Loại phòng ban <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Loại phòng ban"
                  vid="typeId"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.typeId"
                    :options="departmentTypeOptions"
                    :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Trạng thái"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.status"
                    :options="statuses"
                    :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('departmentSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BCol, BRow
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'

export default {
  name: 'DepartmentSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCol, BRow
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetItem: {
        name: '',
        code: '',
        leaderId: null,
        typeId: null,
        status: 1,
        orderNo: 1,
      },
      filterEmployee: {
        page: 1,
        itemsPerPage: 1000,
        status: 1,
      },
      filterDepartmentType: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
      employeeOptions: [],
      departmentTypeOptions: [],
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'department/statuses',
      employees: 'employee/employees',
      departmentType: 'departmentType/dataSources',
    }),
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createDepartments: 'department/createDepartments',
      updateDepartments: 'department/updateDepartments',
      readEmployees: 'employee/getEmployee',
      getDepartmentType: 'departmentType/getDataSources',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      }
      await this.readEmployees(this.filterEmployee)
      this.employeeOptions = this.employees.map(item => ({
        value: item.id,
        label: item.fullName,
      }))
      await this.getDepartmentType(this.filterDepartmentType)
      this.departmentTypeOptions = this.departmentType.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        name: '',
        code: '',
        leaderId: null,
        typeId: null,
        status: 1,
        orderNo: 1,
      }
      this.employeeOptions = []
      this.departmentTypeOptions = []
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createDepartments(this.targetItem)
            : await this.updateDepartments(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('departmentSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
